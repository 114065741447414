import React from 'react';
import './Main.scss';
import Form from '../Form/Form';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formStatus: 'notSending'
        }
    }
    changeStatus = (status) => {
        this.setState({ formStatus: status });
      }
    renderContent = () => {
        const { formStatus } = this.state;
        if (formStatus === 'notSending') {
            return (
                <div className="content">
                    <p className="content__title">AWS, Web, Python, Laravel, React, Node.js, Stripe experts team</p>
                    <p className="content__text">The application form will be sent on your E-mail. Fill it and send it back. We examine it and contact you soon.</p>
                    <Form changeStatus={this.changeStatus} />
                </div>
            );
        } else if (formStatus === 'loading') {
            return (
                <div className="loading">
                    <Loader type="ThreeDots" color="#fff" height={100} width={100} />
                </div>
            );
        } else if (formStatus === 'error') {
            return (
                <div className="message">
                    <p className="message__text">Oh, sorry.... <br/> Request failed&nbsp;&nbsp;<span role="img" aria-label="Crying Face">😢</span></p>
                </div>
            );
        } else if (formStatus === 'sending') {
            return (
                <div className="message">
                    <p className="message__text">ok, thanks&nbsp;&nbsp;<span role="img" aria-label="Slightly Smiling Face">🙂</span></p>
                </div>
            );
        }
    }
    render () {
        return (
            <div className="container">
                <div className="logo"></div>
                <div className="content_container">
                    { this.renderContent() }
                </div>                
            </div>
        )
    }
}