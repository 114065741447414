import React from 'react';
import ReactDOM from 'react-dom';
import './Form.scss';

import axios from 'axios';
import qs from 'qs';

export default class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: ''
        };
    }
    handleChange = (e) => {
        const { name, value } = e.target;
        const field = e.target.closest('.form__field');

        this.setState({[name]: value});

        if (value.length !== 0) {
            field.classList.add('form__field--filled');
        } else {
            field.classList.remove('form__field--filled');
        }
    }
    handleFocus = (e) => {
        const field = e.target.closest('.form__field');
        field.classList.add('form__field--focus');
    }
    handleBlur = (e) => {
        const field = e.target.closest('.form__field');
        field.classList.remove('form__field--focus');
    }
    changeStatus = (status) => {
        this.props.changeStatus(status);
    };
    handleSubmit = (e) => {
        e.preventDefault();        
        this.changeStatus('loading');        

        const user = {
            name: this.state.name,
            email: this.state.email
        };

        const data = qs.stringify({user, 'special': 'amJbsh8BBn2basKJAn'});

        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data,
            timeout: 3000,
            url: 'https://htdev.com/sender.php',
        };

        axios(options)
            .then(result => {
                const data = JSON.parse(result.data);
                if (+data.status === 1) {
                    return this.changeStatus('sending');
                } else {
                    return this.changeStatus('error');    
                }
            })
            .catch(error => {
                console.log(error);
                return this.changeStatus('error');
            });
      }
    render () {
        return (
            <form onSubmit={this.handleSubmit} className="form" autoComplete="off">
                <div className="form__field">
                    <div className="form__placeholder">Name</div>
                    <input required minLength="2" onFocus={this.handleFocus} onBlur={this.handleBlur} className="form__input" type="text" name="name" onChange={this.handleChange} value={this.state.name} />
                </div>
                <div className="form__field">
                    <div className="form__placeholder">E-mail</div>
                    <input required onFocus={this.handleFocus} onBlur={this.handleBlur} className="form__input" type="email" name="email" onChange={this.handleChange} value={this.state.email} />
                </div>
                <input className="form__submit" type="submit" value="Send" />
            </form>
        )
    }
}